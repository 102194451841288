import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="pb4-ns">

        <div className="row pt3">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
            <h1>Not Found</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
            <p>You just hit a route that doesn&#39;t exist.</p>
          </div>
        </div>

      </div>
  </Layout>
)

export default NotFoundPage
